<template>
  <div class="background background-footer">
    <footer class="global-footer-wrapper section">
      <div class="global-footer section-inner">
        <div class="global-footer-primary">
          <div class="global-footer-primary-left">
            <StoryblokComponent
              v-if="content.logo?.length"
              :blok="content.logo[0]"
              class="global-footer-logo"
              :svg-force-dimensions="true"
            />
            <div
              v-if="description?.length"
              class="description"
              v-html="description"
            ></div>
          </div>
          <div class="global-footer-links">
            <div
              v-for="item in content.footer_links"
              :key="item.uid"
              class="global-footer-links-col"
            >
              <p v-if="item.title">
                <strong>{{ item.title }}</strong>
              </p>
              <ul v-if="content.footer_links?.length">
                <li
                  v-for="link in item.links"
                  :key="link.uid"
                  class="global-footer-links-item"
                >
                  <NuxtLink
                    :to="strippedURL(link.link_url)"
                    :target="link.link_url?.target || '_self'"
                    class="size-2xs"
                    @click="
                      tracking({
                        event: 'click.navigation',
                        click: useSnakeCaseFilter(link.link_label),
                        click_chapter1: 'footer',
                        click_chapter2: 'link',
                        click_chapter3: useSnakeCaseFilter(item.title),
                      })
                    "
                    >{{ link.link_label }}</NuxtLink
                  >
                </li>
              </ul>
            </div>
          </div>
          <ul
            v-if="content.social_networks"
            class="global-footer-social-networks"
          >
            <li
              v-for="socialNetwork in content.social_networks"
              :key="socialNetwork.uid"
            >
              <NuxtLink
                :to="strippedURL(socialNetwork.link)"
                :target="socialNetwork.link?.target || '_self'"
                :title="socialNetwork.title"
                class="size-2xs"
                @click="
                  tracking({
                    event: 'click.navigation',
                    click: useSnakeCaseFilter(socialNetwork.title),
                    click_chapter1: 'footer',
                    click_chapter2: 'link',
                  })
                "
              >
                <SvgIcon :name="`func_${socialNetwork.logo}`" />
              </NuxtLink>
            </li>
          </ul>
        </div>
        <div
          v-if="content.footer_institutional_logos?.length"
          class="global-footer-logos-wrapper"
        >
          <ul
            :class="`global-footer-logos${
              content.footer_institutional_logos?.length === 3
                ? ' full-width'
                : ''
            }`"
          >
            <li
              v-for="logo in content.footer_institutional_logos"
              :key="logo?._uid"
            >
              <template
                v-if="
                  logo.pdf_file?.filename?.length ||
                  (logo.link?.linktype === 'url' && logo.link?.url?.length)
                "
              >
                <a
                  :href="logo.pdf_file?.filename || logo.link?.url"
                  :title="logo.link_title"
                  target="_blank"
                >
                  <StoryblokComponent :blok="logo.logo[0]" :size-hint="15" />
                </a>
              </template>
              <template
                v-else-if="
                  logo.link?.linktype === 'story' &&
                  logo.link?.story?.url?.length
                "
              >
                <NuxtLink
                  :to="strippedURL(logo.link?.story?.url)"
                  :title="logo.link_title"
                  :target="logo.link?.target || null"
                >
                  <StoryblokComponent :blok="logo.logo[0]" :size-hint="15" />
                </NuxtLink>
              </template>
              <template v-else>
                <StoryblokComponent :blok="logo.logo[0]" :size-hint="15" />
              </template>
            </li>
          </ul>
        </div>
        <ul class="global-footer-copyright-and-other-links size-2xs">
          <li class="global-footer-copyright">
            {{ content.copyright }}
          </li>
          <template v-if="content.other_links?.length">
            <li v-for="link in content.other_links" :key="link.uid">
              <NuxtLink
                :to="strippedURL(link.link_url)"
                :target="link.link_url?.target || '_self'"
              >
                {{ link.link_label }}
              </NuxtLink>
            </li>
          </template>
        </ul>
      </div>
    </footer>
  </div>
</template>

<script setup>
import { useTrackingStore } from "@/stores/tracking";
const config = useRuntimeConfig();
const trackingStore = useTrackingStore();
const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
});
const { content } = toRefs(props);
const description = useRenderRichText(content.value.description);
const countryPrefix = config.public["storyblokCountryPrefix"];

const tracking = (data) => {
  trackingStore.sendTrackingData(data);
};
</script>

<style lang="scss">
body.header-facility .global-footer-wrapper {
  padding-bottom: 3.5rem;
}
</style>
<style lang="scss" scoped>
.global-footer-wrapper {
  background: var(--solid-07);
  padding-top: 2.5rem;
  padding-bottom: 1.12rem;
  @include for-tablet-landscape-up {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
}
.global-footer {
  display: grid;
  gap: 0.625rem 0.69rem;
  color: var(--solid-07);
  @include for-tablet-landscape-up {
    grid-template-columns: repeat(2, 1fr);
  }

  a {
    color: var(--solid-07);
    text-decoration: none;
  }

  &-logo {
    :deep(.asset-image) {
      max-width: 9.17rem;
      @include for-tablet-landscape-up {
        max-width: 18rem;
      }
    }
  }

  &-primary,
  &-logos-wrapper,
  &-copyright-and-other-links {
    border-radius: $radius-m;
    @include for-tablet-landscape-up {
      border-radius: $radius-l;
    }
  }

  &-primary {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 4rem;
    background-color: var(--footer-bg);
    padding: 2.5rem col-span() 1.25rem;

    @include for-tablet-landscape-up {
      grid-column: span 2;
      padding: 3.25rem 2.5rem 5rem;
      row-gap: 0;

      display: grid;
      grid-template-columns: 7fr 3fr 8fr;
      grid-template-areas:
        "description . links"
        "networks . links";

      &-left {
        grid-area: description;
        margin-bottom: 4.375rem;
      }

      & + .global-footer-copyright-and-other-links {
        grid-column: span 2;
      }
    }
  }

  .description {
    margin-top: 1.5rem;
    @include for-tablet-landscape-up {
      margin-top: 2rem;
    }
  }

  &-links {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(6rem, 1fr));
    row-gap: 3rem;
    justify-content: space-between;
    column-gap: 1rem;
    width: 100%;
    @include for-tablet-landscape-up {
      grid-template-columns: repeat(auto-fit, minmax(9rem, 1fr));
      grid-area: links;
      justify-content: flex-end;
      margin-top: 1.75rem;
    }
    a {
      color: var(--footer-link);
    }
    &-col {
      p + ul {
        padding-top: 0.75rem;

        @include for-tablet-landscape-up {
          padding-top: 1.5rem;
        }
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
      }
    }
  }

  &-social-networks {
    position: relative;
    z-index: 2;
    display: flex;
    gap: 1.5rem;
    @include for-tablet-landscape-up {
      grid-area: networks;
      align-self: end;
    }

    .icon {
      display: block;
    }
  }

  &-logos-wrapper,
  &-copyright-and-other-links {
    padding: 1.5rem col-span();
    @include for-tablet-landscape-up {
      padding: 1rem col-span();
    }
  }

  &-logos-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--solid-06);
    @include for-tablet-landscape-up {
      padding-right: 2.5rem;
      padding-left: 2.5rem;
    }
  }

  &-logos {
    display: flex;
    align-items: center;
    justify-content: space-around;
    column-gap: 2rem;
    &.full-width {
      width: 100%;
      justify-content: space-between;
    }

    :deep(.asset-image) {
      max-height: 3.125rem;
      width: auto;
    }
  }

  &-copyright-and-other-links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 1.5rem col-span() 1.5rem;
    gap: 0.75rem;
    background-color: var(--brand);
    @include for-tablet-portrait-up {
      display: flex;
      padding: 2.5rem;
      gap: 1.5rem;
      align-items: center;
    }
  }
  &-copyright {
    grid-column: span 2;
    white-space: nowrap;
    @include for-tablet-portrait-up {
      flex: 1;
    }
  }
}
</style>
