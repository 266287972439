<template>
  <div
    ref="submenu"
    :class="[
      'sub-menu-grid',
      `col-${grid.length}`
    ]"
  >
    <div
      v-for="item in grid"
      :key="item._uid"
      :class="[
        'sub-menu-column',
        {'has-spacer': item.has_spacer === true}
      ]"
    >
      <ul v-if="item.column.length > 0">
        <li
          v-for="link in item.column"
          :key="link._uid"
          :class="[
            'sub-menu-list-item',
            {'level-1': link.component === 'blok-link-level-1'},
            {'level-2': link.component === 'blok-link-level-2'}
          ]"
          @click="trackingStore.sendTrackingData({
            event: 'click.navigation',
            click: useSnakeCaseFilter(link.link_label),
            click_chapter1: 'sub_menu',
            click_chapter2: 'link',
          })"
        >
          <template v-if="link.link_url.id">
            <NuxtLink
              :class="[
                'sub-menu-link',
                {'link-level-1 bold': link.component === 'blok-link-level-1'},
                {'link-level-2': link.component === 'blok-link-level-2'}
              ]"
              :to="strippedURL(link.link_url)"
              :target="
                link.link_url.target === '_blank'
                  ? '_blank'
                  : '_self'
              "
            >
              {{ link.link_label }}
            </NuxtLink>
          </template>
          <template v-else>
            <span  class="sub-menu-link link-level-1 bold">
              {{ link.link_label }}
            </span>
          </template>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
const trackingStore = useTrackingStore();
const props = defineProps({
  grid: {
    type: Array,
    required: true,
    default: () => {
      return [];
    },
  },
});
const { grid } = toRefs(props); // eslint-disable-line
const submenu = ref();

const bodyStyle = computed(() => {
  let styles = '';
  submenu.value?.querySelectorAll('.sub-menu-column.has-spacer').forEach((element, index) => {
    if(element.previousSibling.classList.contains('sub-menu-column')) {
      const spacer = element.previousSibling.querySelector('.sub-menu-list-item')?.offsetHeight;
      if(spacer) {
        styles = styles + `--sub-menu-spacer-${index}: ${spacer}px;`;
      }
    }
  });
  return styles;
});

useHead({
  bodyAttrs: {
    style: bodyStyle,
  },
});
</script>

<style lang="scss" scoped>
.sub-menu-grid {
  &.col-1 {
    @include for-tablet-landscape-up {
      width: 100%;
    }
  }

  &.col-2 {
    @include for-tablet-portrait-up {
      display: flex;
      gap: col-span(2);
    }

    @include for-tablet-landscape-up {
      width: 100%;
      gap: col-span(1);
    }

    .sub-menu-column {
      @include for-tablet-portrait-up {
        width: 50%;
      }
    }
  }

  &.col-3 {
    @include for-tablet-portrait-up {
      display: grid;
      grid-template-columns: 1fr 1fr; 
      grid-template-rows: 1fr 1fr; 
      gap: 2rem col-span(2); 
    }

    @include for-tablet-landscape-up {
      grid-template-columns: 1fr 1fr 1fr; 
      grid-template-rows: 1fr;
      gap: col-span(1);
    }

    .sub-menu-column {
      &:nth-child(2) {
        @include for-tablet-portrait-up {
          grid-column-start: 1;
          grid-column-end: 2;
          grid-row-start: 2;
          grid-row-end: 3;
        }

        @include for-tablet-landscape-up {
          grid-column: inherit;
          grid-row: inherit;
        }
      }
    }
  }

  &.col-4 {
    @include for-tablet-portrait-up {
      display: grid;
      grid-template-columns: 1fr 1fr; 
      grid-template-rows: 1fr 1fr; 
      gap: 2rem col-span(2); 
    }

    @include for-tablet-landscape-up {
      grid-template-columns: 1fr 1fr 1fr 1fr; 
      grid-template-rows: 1fr;
      gap: col-span(1);
    }

    .sub-menu-column {
      &:nth-child(2) {
        @include for-tablet-portrait-up {
          grid-column-start: 1;
          grid-column-end: 2;
          grid-row-start: 2;
          grid-row-end: 3;
        }

        @include for-tablet-landscape-up {
          grid-column: inherit;
          grid-row: inherit;
        }
      }
    }
  }
}

.sub-menu-column.has-spacer {
  @include for-tablet-portrait-up {
    --extra-padding : 0.75rem;
    padding-top: calc(var(--sub-menu-spacer-0) + var(--extra-padding));
  }

  @include for-tablet-landscape-up {
    --extra-padding : 1.5rem;
  }

  & ~ .sub-menu-column.has-spacer {
    @include for-tablet-portrait-up {
      padding-top: calc(var(--sub-menu-spacer-1) + var(--extra-padding));
    }
  }
  & ~ .sub-menu-column.has-spacer ~ .sub-menu-column.has-spacer {
    @include for-tablet-portrait-up {
      padding-top: calc(var(--sub-menu-spacer-2) + var(--extra-padding));
    }
  }
  & ~ .sub-menu-column.has-spacer ~ .sub-menu-column.has-spacer ~ .sub-menu-column.has-spacer {
    @include for-tablet-portrait-up {
      padding-top: calc(var(--sub-menu-spacer-3) + var(--extra-padding));
    }
  }
}

.level-1 + .level-1 {
  margin-top: 1.75rem;

  @include for-tablet-landscape-up {
    margin-top: 1.5rem;
  }
}

.level-1 + .level-2 {
  margin-top: 0.75rem;

  @include for-tablet-landscape-up {
    margin-top: 1.5rem;
  }
}

.level-2 + .level-2 {
  margin-top: 0.5rem;

  @include for-tablet-landscape-up {
    margin-top: 0.75rem;
  }
}

.level-2 + .level-1 {
  margin-top: 2rem;

  @include for-tablet-landscape-up {
    margin-top: 2.5rem;
  }
}

.sub-menu-column + .sub-menu-column {
  margin-top: 2rem;

  @include for-tablet-portrait-up {
    margin-top: 0;
  }

  &.has-spacer {
    margin-top: 0.5rem;

    @include for-tablet-portrait-up {
      margin-top: 0;
    }
  }
}

.sub-menu-link {
  text-decoration: none;
}

.link-level-1 {
  @include size-m;
  display: flex;
  gap: 0.5rem;
  margin-left: -0.5rem;

  &::before {
    content: "";
    width: 1.5rem;
    height: 1.5rem;
    background-image: url("../assets/svg/func_right.svg");
    flex-shrink: 0;
  }
}

.link-level-2 {
  @include size-s;
}
</style>